import {MenuCreateOptionInterface} from 'kernel/types/module'
class menu {
  private vm: Vue
  constructor() {
    this.vm = window.rootVm
  }

  $t(args: string) {
    return this.vm.$t(args)
  }

  get() : {[key: string]: any} {
    return {
      admin: [
        {
          label: this.vm.$t('page.provider'),
          icon: 'fa fa-info-circle',
          create: (options: MenuCreateOptionInterface) => options.hasRole(['ROLE_PROVIDER']),
          group: [
            {
              label: this.vm.$t('page.provider-config'),
              route: {
                name: 'provider-config',
              },
            },
            {
              label: this.vm.$t('page.provider'),
              route: {
                name: 'provider',
              },
            },
          ],
        },
      ],
    }
  }
}

export default () => (new menu()).get()
